import { Input } from "antd";
import React from "react";

const CommonInput = ({
  placeHolder = "",
  disabled=false,
  name = "",
  value = "",
  type = "text",defaultValue,
  onInput = () => {},
  onChange = () => {},
  className = "rounded-none  hover:border border-secondary hover:border-secondary hover:shadow-none focus-within:border focus-within:border-gray-300 focus:border focus:border-gray-300",
}) => {
  return (
    <div>
      <Input
        type={type}
        defaultValue={defaultValue}
        name={name}
        disabled={disabled}
        placeholder={placeHolder}
        value={value}
        onInput={onInput}
        onChange={onChange}
        className={className}
      />
    </div>
  );
};

export default CommonInput;
